/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import appleTouchIcon from "../../static/assets/images/apple-touch-icon.png"
import favicon32 from "../../static/assets/images/favicon-32x32.png"
import favicon16 from "../../static/assets/images/favicon-16x16.png"
import safariPinnedTab from "../../static/assets/images/safari-pinned-tab.svg"
import shareImage from "../../static/assets/images/og-image.jpg"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            primaryColor
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `title`,
          content: title,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: shareImage,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: shareImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: metaDescription,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `application-name`,
          content: site.siteMetadata.title,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `black-translucent`,
        },
        {
          name: `apple-mobile-web-app-title`,
          content: site.siteMetadata.title,
        },
        {
          name: `msapplication-TileColor`,
          content: site.siteMetadata.primaryColor,
        },
        {
          name: `theme-color`,
          content: site.siteMetadata.primaryColor,
        },
      ].concat(meta)}
    >
      <link rel="apple-touch-icon" sizes="128x128" href={`${appleTouchIcon}?v=3`} />
      <link rel="icon" type="image/png" href={`${favicon32}?v=3`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${favicon16}?v=3`} sizes="16x16" />
      <link rel="mask-icon" href={`${safariPinnedTab}?v=3`} color={site.siteMetadata.primaryColor} />
      <link rel="preload" href="/shader/assets/maps/Flags.png" as="image" />
      <link rel="preload" href="/shader/assets/maps/Normals.jpg" as="image" />
      <link rel="preload" href="/shader/js/es5/app.js" as="script" />
    </Helmet>
  )
} 

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
